<template>
  <div class="asset-index">
    <subnav currPage="footerAsset"></subnav>
    <v-container class="my-5 mx-sm-1 pt-sm-5">
      <div
        class="asset-item d-flex align-stretch mb-5"
        v-for="(item, i) in orders"
        :key="i"
      >
        <div class="asset-info flex-grow-1 rounded-lg can-click py-2 px-3 mr-2" @click="toOrderDetails(item)">
          <div class="d-flex align-start">
            <img :src="`${require(`@/assets/img/icon-polygon-${item.token_name.toLowerCase()}-grey.svg`)}`" :alt="`${item.token_name.toLowerCase()}-${$route.params.interestToken}`" class="mr-2 token-img">
            <div class="flex-grow-1 rem-0">
              <div class="lightGrey2--text d-flex justify-space-between">
                <span>{{ $t('orderId', {id: item.id}) }}</span>
                <span>{{ $t('orderTime', {date: item.totalTime}) }}</span>
              </div>

              <div class="d-flex justify-space-between mb-2">
                <div>
                  <div class="font-share-tech font-w-4 mb-1">
                    <span class="rem-18 rem-sm-22 lightPrimary2--text mr-1">{{ item.token_name }}</span>
                    <span class="rem-4 rem-sm-6 lightGrey1--text">/{{ $route.params.interestToken.toUpperCase() }}</span>
                  </div>
                  <div class="lightGrey2--text mb-1 rem-0">{{ $t('orderRate', {value: item.rate, interestToken: $route.params.interestToken.toUpperCase(), depositToken: item.token_name}) }}</div>
                  <div class="font-share-tech font-w-4 mb-1">
                    <span class="rem-8 rem-sm-10 mr-1" style="color: #6FDFC2;">{{ item.amount.toLocaleString() }} {{ item.token_name }}</span>
                    <span class="rem-3 rem-sm-5" style="color: #F4FFFC;">/ {{ (item.amount * item.rate).toLocaleString() }} {{ $route.params.interestToken.toUpperCase() }}</span>
                  </div>
                </div>
                <img src="@/assets/img/icon-setting.svg" alt="details">
              </div>
            </div>
          </div>
          <contractProgress :data="item"></contractProgress>
        </div>
        <div class="asset-btns font-w-4 text-center">
          <div class="asset-btn rounded-lg d-flex flex-column justify-center align-center px-4 mb-1" :class="item.status ? 'can-click' : 'disabled'" :style="{borderColor: now - item.start_day > (60 * 60 * 24) ? '#FF5B45' : ''}" @click="showDialog('redeem', i)">
            <img :src="`${require(`@/assets/img/icon-polygon-${item.token_name.toLowerCase()}-${!item.status ? 'disabled' : now - item.start_day > (60 * 60 * 24) ? 'warning' : 'primary'}.svg`)}`" :alt="item.token" class="mb-1">
            <span class="rem-0" :class="!item.status ? 'lightGrey2--text' : now - item.start_day > (60 * 60 * 24) ? 'warning-text' : 'lightPrimary2--text'">{{ $t('redeemDepositToken') }}</span>
          </div>
          <div class="asset-btn rounded-lg d-flex flex-column justify-center align-center px-4" :class="item.status && item.payment_amount !== '0' ? 'can-click' : 'disabled'" @click="showDialog('claim', i)">
            <img :src="`${require(`@/assets/img/icon-polygon-${$route.params.interestToken}-${item.status && item.payment_amount !== '0' ? 'primary' : 'disabled'}.svg`)}`" :alt="item.token" class="mb-1">
            <span class="rem-0" :class="item.status && item.payment_amount !== '0' ? 'lightPrimary2--text' : 'lightGrey2--text'">{{ $t('claimInterest') }}</span>
          </div>
        </div>
      </div>
    </v-container>

    <v-dialog persistent fullscreen v-model="loadingShow" style="height: 100vh;">
      <div class="d-flex flex-column justify-center align-center" style="height: 100%; background-color: rgba(0, 0, 0, .7);">
        <img class="mb-10" src="@/assets/img/icon-loading.svg" alt="loading">
        <div style="margin-bottom: 120px">{{ $t('waitForChainData') }}</div>
        <div class="mb-5">{{ $t('depositNowIfHavent') }}</div>
        <v-btn
          class="border-radius-3 font-w-4 px-2 rem-6 rem-md-11"
          :height="$store.state.nowWidth > 960 ? 51 : 39"
          :max-width="$store.state.nowWidth > 960 ? 350 : 267"
          width="95%"
          light
          color="lightPrimary2"
          @click="$router.push({name: 'Deposit', params: {lang: $store.state.locale, interestToken: $store.state.interestTokens[0].token, depositToken: $store.state.depositTokens[0].token}})"
        >
          {{ $t('depositImmediately') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import subnav from '@/components/subnav.vue'
import contractProgress from '@/components/progress.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data () {
    return {
      currIndex: 0,
      loadingShow: true
    }
  },
  components: {
    subnav,
    contractProgress,
  },
  methods: {
    showDialog(type, index) {
      let item = this.orders[index]
      if (type === 'redeem') {
        if (!item.status) return;
      } else {
        if (!item.status || item.payment_amount === '0') return;
      }
      this.currIndex = index
      this.$store.commit('updateDialog', {isShow: true, type: type, data: item, currIndex: index})
    },
    toOrderDetails(item) {
      this.$router.push({
        name: 'Order',
        params: {
          lang: this.$store.state.locale,
          interestToken: this.$route.params.interestToken,
          depositToken: item.token_name.toLowerCase(),
          orderId: item.id,
          data: item,
          currIndex: this.currIndex
        }
      })
    }
  },
  async created() {
    this.loadingShow = true
    // this.$store.commit('updateLoading', {isShow: true, text: this.$t('waitLoading')})
    await this.getOrders()
    this.loadingShow = false
    // this.$store.commit('updateLoading', {isShow: false, text: ''})
  }
}
</script>
<style lang="scss">
.asset-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    margin: 0 auto;
    background: radial-gradient(188.38% 188.38% at 50% -88.38%, rgba(99, 99, 99, 0.8) 0%, rgba(71, 71, 71, 0.8) 40.12%, rgba(49, 49, 49, 0.8) 100%);
    border-radius: 11px;
  }
  .asset-item {
    img.token-img {
      margin-top: 26px;
    }
    .asset-info, .asset-btn {
      background: radial-gradient(84.24% 231.72% at 49.83% -125.27%, rgba(99, 99, 99, 0.8) 0%, rgba(71, 71, 71, 0.8) 39.06%, rgba(49, 49, 49, 0.8) 95.83%);
      border: 1px solid var(--v-lightPrimary2-base);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(5px);
    }

    .asset-btns {
      .asset-btn {
        height: calc((100% - 4px) / 2);
        &.disabled {
          border-color: #5D5D5D !important;
        }
      }
    }
    .warning-text {
      color: #FF5B45;
    }
  }
}

.v-input.text-center {
  input {
    text-align: center;
  }
}

img[alt="loading"] {
  animation: spin 1s infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>